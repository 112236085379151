import { Box, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { NavBar } from './NavBar';

export const MainLayout = () => {
  return (
    <>
      <Flex direction='column' height='100vh' backgroundColor={'#F3F4F7'}>
        <Box position='fixed' top={0} left={0} right={0} zIndex='sticky' bg='white' width='100%'>
          <NavBar />
        </Box>
        {/* The marginTop here is the height of the top nav on desktop or mobile (incl its top border stripe) */}
        <Flex flex='1' overflowY='auto' marginTop={{ base: '55px', sm: '55px', md: '80px' }} id='mainLayoutContainer'>
          <Box width='100%'>
            <Outlet />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};
