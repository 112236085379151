import { formErrorAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(formErrorAnatomy.keys);

const searchForm = definePartsStyle({
  text: {
    fontSize: '15px',
    fontWeight: 'bold'
  }
});

export const formErrorTheme = defineMultiStyleConfig({
  variants: {
    searchForm
  }
});
