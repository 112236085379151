import ReactGA from 'react-ga4';
import AppSettingsService from '../appSettings/AppSettingsService';

class GoogleAnalyticsService {
  private initialized: boolean;

  constructor() {
    this.initialized = false;
  }

  initialize() {
    if (!this.initialized) {
      AppSettingsService.getAppSettings().then((settings) => {
        if (settings && settings.data && settings.data.GoogleAnalyticsKey) {
          ReactGA.initialize(settings.data.GoogleAnalyticsKey);
          this.initialized = true;
        }
      });
    }
  }

  recordButtonClick = (buttonLabel: string) => {
    if (!this.initialized) {
      return;
    }
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: buttonLabel
    });
  };

  recordPageView = () => {
    ReactGA.send({
      hitType: 'pageview',
      path: window.location.pathname + window.location.search
    });
  };
}

const googleAnalyticsService = new GoogleAnalyticsService();
export default googleAnalyticsService;
