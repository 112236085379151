(window as any).global ||= window;

import '@fontsource-variable/montserrat';
import ReactDOM from 'react-dom';
import { App } from 'src/App';
import { AllAccessRoles } from './constants/appRoles';
import { getAxios } from './lib/axios';
(window as any).getAxios = getAxios;
(window as any).superAdminRoles = AllAccessRoles;
// Version 2: Using functions

// const container = document.getElementById('root')!;
// const root = createRoot(container);
// root.render(<App />);

// Rendering this way because it stops google map flickering when moving or panning.
// https://github.com/google-map-react/google-map-react/issues/1117#issuecomment-1203479297
ReactDOM.render(<App />, document.getElementById('root'));
