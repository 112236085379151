import { Box, Flex, Show } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { NavBar } from './NavBar';

export const BoxLayout = () => {
  return (
    <>
      <Flex direction='column' height='100vh' backgroundColor={'#faf9f67f'}>
        <Box position='fixed' top={0} left={0} right={0} zIndex='sticky' bg='white' width='100%'>
          <NavBar />
        </Box>
        <Show above='md'>
          <Flex flex='1' overflowY='auto' marginTop='80px'>
            <Box
              w={['100%', '100%', '80%', '80%', '80%', '60%']}
              maxW={'1200px'}
              marginLeft={'auto'}
              marginRight={'auto'}
              paddingLeft={'5px'}
              paddingRight={'5px'}
              paddingTop={'10px'}>
              <Outlet />
            </Box>
          </Flex>
        </Show>
        <Show below='md'>
          <Flex flex='1' overflowY='auto' marginTop='55px'>
            <Box width='100%'>
              <Outlet />
            </Box>
          </Flex>
        </Show>
      </Flex>
    </>
  );
};
