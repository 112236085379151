import Axios, { AxiosInstance } from 'axios';

import { API_URL, MAIN_DB_API_URL } from './config';

export const setAxiosOnError = (handler: (error: any) => void) => {
  axiosOnError = handler;
};

export let axiosOnError: (error: any) => void;

const buildHttpClient = ({ baseUrl = '' }): AxiosInstance => {
  const httpClient = Axios.create({
    baseURL: baseUrl,
    withCredentials: true
  });

  httpClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status !== 401) {
        if (axiosOnError) {
          axiosOnError(error);
        }
      } else {
        if (window.location.pathname !== '/login' && error?.request?.responseURL?.indexOf('/auth/user') < 0) {
          window.location.href = '/login';
        }
      }
      return Promise.reject(error);
    }
  );

  return httpClient;
};
export const axiosClient = buildHttpClient({ baseUrl: API_URL });

export const mainDbAxiosClient = buildHttpClient({ baseUrl: MAIN_DB_API_URL });

export const getAxios = () => {
  return axiosClient;
};

export const getMainDbAxios = () => {
  return mainDbAxiosClient;
};
