import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const variants = {
  blueCard: definePartsStyle({
    container: {
      backgroundColor: 'blue.100',
      borderRadius: '0',
      padding: 0,
      margin: 0
    }
  }),
  fieldArray: definePartsStyle({
    container: {
      background: 'rgb(243, 243, 243)',
      border: '1px solid #ebebeb',
      borderRadius: '8px',
      boxShadow: 'none'
    },
    header: {
      padding: '12px'
    },
    body: {
      padding: '8px'
    }
  }),
  pageCard: definePartsStyle({
    container: {
      background: 'white',
      border: '1px solid #ebebeb',
      borderRadius: '8px',
      boxShadow: 'md',
      padding: '8px'
    },
    header: {
      padding: '12px 12px 0px 12px'
    },
    body: {
      padding: '12px'
    }
  }),

  roomType: definePartsStyle({
    container: {
      border: 'none',
      borderRadius: '0',
      boxShadow: 'none',
      padding: '4px'
    },
    body: {
      background: '#F6F6F6',
      padding: '2px'
    }
  }),
  resultCard: definePartsStyle({
    container: {
      background: 'white',
      border: '1px solid #A3A3A3',
      borderRadius: '8px',
      boxShadow: 'none',
      padding: '8px'
    }
  })
};
const baseStyle = definePartsStyle({
  // define the part you're going to style
  // container: {
  //   boxShadow: 'none',
  // },
});

export const cardTheme = defineMultiStyleConfig({
  baseStyle,
  variants
});
