import { getAxios } from '../../lib/axios';
import AppSettings from './AppSettings';

class AppSettingsService {
  constructor() {}

  public getAppSettings = () => {
    return getAxios().get<AppSettings>('/api/settings');
  };
}
export default new AppSettingsService();
