import { ApplicationRoutes } from 'src/constants/applicationRoutes';

export const UnauthorizedRoutes = [
  {
    path: ApplicationRoutes.account.login,
    async lazy() {
      const component = await import('src/features/auth/Login');
      return { Component: component.LoginPage };
    }
  },
  {
    path: ApplicationRoutes.account.resetPassword,
    async lazy() {
      const component = await import('src/features/auth/ResetPassword');
      return { Component: component.ResetPasswordPage };
    }
  },
  {
    path: ApplicationRoutes.account.forgotPassword,
    async lazy() {
      const component = await import('src/features/auth/ForgotPassword');
      return { Component: component.ForgotPasswordPage };
    }
  },
  {
    path: ApplicationRoutes.account.logout,
    async lazy() {
      const component = await import('src/features/auth/Logout');
      return { Component: component.Logout };
    }
  }
];
