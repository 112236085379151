/* eslint-disable @typescript-eslint/naming-convention */
declare global {
  interface Window {
    FLS_REACT_APP_API_BASE_URL: string | undefined;
    FLS_REACT_APP_MAINDB_API_BASE_URL: string | undefined;
  }
}

export const API_URL = window.FLS_REACT_APP_API_BASE_URL || (import.meta.env.VITE_REACT_APP_API_BASE_URL as string);

export const MAIN_DB_API_URL =
  window.FLS_REACT_APP_MAINDB_API_BASE_URL || (import.meta.env.VITE_REACT_APP_MAIN_DB_API_BASE_URL as string);
export const ApplicationName = 'frontier_booking';
