import { extendTheme } from '@chakra-ui/react';
import { buttonTheme } from './components/button';
import { cardTheme } from './components/card';
import { formTheme } from './components/form';
import { formErrorTheme } from './components/formError';
import { headerTheme } from './components/heading';
import { inputTheme } from './components/input';
import { numberInputTheme } from './components/numberInput';
import { selectTheme } from './components/select';
import { tableTheme } from './components/table';
import { textTheme } from './components/text';
import { switchTheme } from './components/switch';

export const ChakraTheme = extendTheme({
  components: {
    Card: cardTheme,
    Input: inputTheme,
    Button: buttonTheme,
    Select: selectTheme,
    NumberInput: numberInputTheme,
    Form: formTheme,
    Text: textTheme,
    Heading: headerTheme,
    FormError: formErrorTheme,
    Table: tableTheme,
    Switch: switchTheme
  },
  fonts: {
    heading: `'Montserrat Variable', 'sans-serif'`,
    body: `'Montserrat Variable', 'sans-serif'`
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: 'white.100'
      }
    }
  },
  colors: {
    radioButton: {
      selected: '#f05a29',
      unselected: 'white',
      border: '#f05a29'
    },
    icons: {
      selectedBackground: 'white',
      unselectedBackground: 'white',
      selected: '#f05a29',
      unselected: '#255993'
    },
    orange: {
      100: '#f05a29'
    },
    blue: {
      100: '#255993'
    },
    green: {
      100: '#299937'
    },
    primary: {
      100: '#f05a29',
      200: '#F37F59'
    },
    secondary: {
      100: '#255993'
    }
  }
});
