import { ChakraBaseProvider } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { useAuth } from '@cksoftware/react-base';
import { useEffect } from 'react';
import { ErrorContainer } from './ErrorContainer';
import { FrontierUser } from './globalModels/frontierUser';
import { FrontierRoutes } from './routes/FrontierRoutes';
import { ChakraTheme } from './theme/chakraTheme';
import googleAnalyticsService from './features/googleAnalytics/GoogleAnalyticsService';

const queryClient = new QueryClient();

export const App = () => {
  const { initUser, hasLoaded } = useAuth<FrontierUser>();

  useEffect(() => {
    async function initAuth() {
      await initUser();
    }
    initAuth();
    googleAnalyticsService.initialize();
  }, []);

  useEffect(() => {
    googleAnalyticsService.recordPageView();
  }, [window.location]);

  return (
    <div className='App'>
      {/* Your page content goes here */}
      <QueryClientProvider client={queryClient}>
        <ChakraBaseProvider theme={ChakraTheme} cssVarsRoot='body' resetCSS={true}>
          <ErrorContainer>{hasLoaded && <RouterProvider router={FrontierRoutes} />}</ErrorContainer>
        </ChakraBaseProvider>
      </QueryClientProvider>
    </div>
  );
};
