import { Box } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationRoutes } from 'src/constants/applicationRoutes';

export const Home = () => {
  const auth = useAuth();
  const nav = useNavigate();

  useEffect(() => {
    if (!auth.hasLoaded) {
      return;
    }
    if (auth.isAuthenticated) {
      nav(ApplicationRoutes.booking.hotelSearchLanding);
    } else {
      nav(ApplicationRoutes.account.login);
    }
  }, [auth.isAuthenticated, auth.user, auth.hasLoaded]);
  return <Box>Please Wait...</Box>;
};
