import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    // ...
  },
  thumb: {
    bg: 'white'
  },
  track: {
    bg: 'rgb(221, 222, 224)',
    _checked: {
      bg: 'orange.100'
    }
  }
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
